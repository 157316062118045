import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '../../state/mainAppState';
import { MagicUseCase } from '../magicUseCase';
import { GetRentalApplicationStatusUseCase } from './application-steps/getRentalApplicationStatusUseCase';

export class GetRentalApplicationUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    if (!credentials.id || !credentials.password) {
      return;
    }

    const existingApplication = await rentalApplicationGateway.getRentalApplication(credentials);
    const settings = await rentalApplicationGateway.getApplicationSettings();

    if (existingApplication) {
      this.getState().user.rentalApplication.application = existingApplication;
    }

    if (!this.getState().user.rentalApplication.errors) {
      this.getState().user.rentalApplication.errors = {};
    }

    this.getState().user.rentalApplication.settings = settings;
    await new GetRentalApplicationStatusUseCase(this.eventEmitter).execute(credentials);
  }
}
