import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { TransUnionScreeningStatus } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { GetRentalApplicationScreeningQuestionsUseCase } from '../screening/getRentalApplicationScreeningQuestionsUseCase';
import { SetCurrentStepUseCase } from './setCurrentStepUseCase';

export class GetRentalApplicationStatusUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    if (this.getState().user.rentalApplication.application?.isDraft) {
      return;
    }
    if (!this.getState().user.rentalApplication.isPaid) {
      if (this.getState().user.rentalApplication.settings?.requiresPayment) {
        const isPaid = await rentalApplicationGateway.fetchRentalApplicationPaymentStatus(credentials);
        this.getState().user.rentalApplication.isPaid = isPaid;
      } else {
        this.getState().user.rentalApplication.isPaid = true;
      }
    }
    if (
      this.getState().user.rentalApplication &&
      !this.getState()?.user.rentalApplication.isPaid &&
      this.getState().user.rentalApplication.settings?.requiresPayment
    ) {
      await new SetCurrentStepUseCase().execute(RentalApplicationSteps.PAYMENT);
      this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/payment`);
      return;
    }

    if (!this.getState().user.rentalApplication.settings) {
      this.getState().user.rentalApplication.settings = await rentalApplicationGateway.getApplicationSettings();
    }
    if (this.getState().user.rentalApplication.settings?.requiresScreening) {
      const transUnionScreening = this.getState().user.rentalApplication.transUnionScreening;
      if (transUnionScreening && !transUnionScreening.questions && transUnionScreening.status !== TransUnionScreeningStatus.NotStarted) {
        await new GetRentalApplicationScreeningQuestionsUseCase().execute(credentials);
      }
      const transUnionScreeningStatus = await rentalApplicationGateway.getTransUnionScreeningStatus(credentials);
      this.getState().user.rentalApplication.transUnionScreening = {
        ...this.getState().user.rentalApplication.transUnionScreening,
        status: transUnionScreeningStatus.status,
        transunionScreeningId: transUnionScreeningStatus.transunionScreeningId,
        transunionScreeningRequestRenterId: transUnionScreeningStatus.transunionScreeningRequestRenterId,
      };
      switch (transUnionScreeningStatus.status) {
        case TransUnionScreeningStatus.NotStarted: {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TRANS_UNION_SCREENING);
          this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/transunion-connection`);
          break;
        }
        case TransUnionScreeningStatus.UnVerified: {
          break;
        }
        case TransUnionScreeningStatus.Verified: {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICATION_SUBMITTED);
          this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/application-submitted`);
          break;
        }
        case TransUnionScreeningStatus.ManualVerificationRequired: {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.MANUAL_VERIFICATION);
          this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/manual-verification`);
          break;
        }
      }
    } else if (this.getState().user.rentalApplication.isPaid || !this.getState().user.rentalApplication.settings?.requiresPayment) {
      await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICATION_SUBMITTED);
      this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/application-submitted`);
    }
  }
}
